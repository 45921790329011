<template>
    <div id="MainHome">
      <el-container>

        <el-main>
          <h3>开发</h3>
          <div class="container-block">
              <el-link class="block_items" href="#/json" target="_blank">Json解析</el-link>
              <el-link class="block_items" href="#/timestamp" target="_blank">时间戳</el-link>
              <el-link class="block_items" href="#/randnum" target="_blank">随机密码</el-link>
              <el-link class="block_items" href="https://www.huatools.com/urlparam-format/" target="_blank">Url转Json</el-link>
              

              <el-link class="block_items" href="https://github.com/chineseocr/darknet-ocr" target="_blank">darknet-ocr</el-link>
              <el-link class="block_items" href="https://ai.ls/" target="_blank">ChatGPT(翻墙)</el-link>
              <el-link class="block_items" href="https://chatbot.theb.ai/" target="_blank">ChatGPT(翻墙)</el-link>
              <el-link class="block_items" href="https://ai.ai365.ink" target="_blank">ChatGPT(翻墙)</el-link>
              
          </div>
          <!--https://fuun.fun/ai-->
          <h3>工具</h3>
          <div class="container-block">
              <el-link class="block_items" href="https://tinify.cn/" target="_blank">图片压缩tinify</el-link>
            <el-link class="block_items" href="https://tongyi.aliyun.com/wanxiang/" target="_blank">通义万相</el-link>
          </div> 

          <h3>Go语言</h3>
          <div class="container-block">
              <el-link class="block_items" href="https://www.zhihu.com/column/quickgo" target="_blank">快学Go语言</el-link>
              <el-link class="block_items" href="https://github.com/unknwon/go-study-index" target="_blank">Go 语言学习资料</el-link>
              <el-link class="block_items" href="https://github.com/shockerli/go-awesome" target="_blank">Go 语言优秀资源整理</el-link>
              <el-link class="block_items" href="https://jasperxu.github.io/gorm-zh/" target="_blank">GORM教程</el-link>
          </div>

          <h3>网盘搜索</h3>
          <div class="container-block">
              <el-link class="block_items" href="https://www.alipansou.com/" target="_blank">猫狸盘搜</el-link>
              <el-link class="block_items" href="https://www.jiumodiary.com/" target="_blank">鸠摩搜索</el-link>
              <el-link class="block_items" href="https://zh.annas-archive.org/" target="_blank">安娜的档案</el-link>
          </div>
      
          <h3>视频站点</h3>
          <div class="container-block">
            <el-link class="block_items" href="https://tv.cctv.com/live/cctv5/" target="_blank">中央电视台体育直播</el-link>
              <el-link class="block_items" href="https://www.subaibaiys.com/" target="_blank">素白白影视</el-link>
              <el-link class="block_items" href="http://www.tvyb01.com/" target="_blank">TVB云播</el-link>
              <el-link class="block_items" href="https://vidhub.top/" target="_blank">vidhub</el-link>
              <el-link class="block_items" href="https://www.nkvod.com/" target="_blank">耐看点播</el-link>
              <el-link class="block_items" href="http://dfys6.com/" target="_blank">东方城影视</el-link>
              <el-link class="block_items" href="https://www.dy2018.com/" target="_blank">电影天堂1</el-link>
              <el-link class="block_items" href="https://www.dygod.net/" target="_blank">电影天堂2</el-link>
          </div>
          <h3>咨询站点</h3>
          <div class="container-block">
              <el-link class="block_items" href="https://www.cyzone.cn/" target="_blank">创业邦</el-link>
              <el-link class="block_items" href="https://www.iresearch.cn/" target="_blank">艾瑞网</el-link>
              <el-link class="block_items" href="https://www.geekpark.net/" target="_blank">极客公园</el-link>
              <el-link class="block_items" href="https://www.huxiu.com/" target="_blank">虎嗅网</el-link>
          </div>
          
          <h3>购物</h3>
          <div class="container-block">
              <el-link class="block_items" href="https://www.smzdm.com/" target="_blank">什么值得买</el-link>
          </div>

          <h3>设计</h3>
          <div class="container-block">
              <el-link class="block_items" href="https://www.zcool.com.cn/" target="_blank">站酷</el-link>
          </div>
        </el-main>
      </el-container>
    </div>
  </template>
  
  
  <script>
  export default {
    name: 'MainHome',
    data: function() {
      return {
  
      }
    },
    components:{
  
    },
    methods: {
    }
  }
  </script>
  
  <style lang="scss">
  
  .container-block {
    border-bottom: 1px solid #ebebeb;
    border-radius: 3px;
    transition: .2s;
    padding-bottom: 1rem;
  }
  .block_items{margin-right: 20px;line-height: 2.5rem;}
  </style>