<template>
<el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
    <el-menu-item index="1">首页</el-menu-item>
    <el-menu-item index="2">Json</el-menu-item>
    <el-menu-item index="3">时间戳</el-menu-item>
    <el-menu-item index="4">随机密码</el-menu-item>
    <!-- <el-submenu index="2">
      <template slot="title">我的工作台</template>
      <el-menu-item index="2-1">选项1</el-menu-item>
      <el-menu-item index="2-2">选项2</el-menu-item>
      <el-menu-item index="2-3">选项3</el-menu-item>
      <el-submenu index="2-4">
        <template slot="title">选项4</template>
        <el-menu-item index="2-4-1">选项1</el-menu-item>
        <el-menu-item index="2-4-2">选项2</el-menu-item>
        <el-menu-item index="2-4-3">选项3</el-menu-item>
      </el-submenu>
    </el-submenu> 
    <el-menu-item index="3" disabled>消息中心</el-menu-item>-->
    
  </el-menu>
</template>
  <script>
  export default {
    name: 'HeaderMenu',
    data() {
      return {
        activeIndex: '',
        activeIndex2: ''
      };
    },
    methods: {
      handleSelect(key, keyPath) {
        if(key == 1) {
          this.$router.push('/')
        } else if(key == 2) {
          this.$router.push('/json')
        } else if(key == 3) {
          this.$router.push('/timestamp')
        }else if(key == 4) {
          this.$router.push('/randnum')
        }
        console.log(key, keyPath);
      }
    }
  }
</script>