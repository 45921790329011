<template>
  <div id="app">
    <headerMenu />
    <!-- 路由匹配到的组件将渲染在这里 -->
    <router-view></router-view>
  </div>
</template>

<script>
  import headerMenu from './components/HeaderMenu.vue';
  
  export default {
    data: function() {
      return {
      }
    },
    components:{
        headerMenu
    },
    methods: {
    }
  }
  </script>

<style>

</style>
