//1.定义路由组件
import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter);
// 导入组件 
import Home from '../pages/MainHome.vue'
import Json from '../pages/JsonParse.vue'
import TimesTamp from '../pages/TimesTamp'
import RandNum from '../pages/RandNum'

const routes = [
    { path: '/', name: 'Home',component: Home },
    { path: '/json', name: 'Json', component: Json },
    { path: '/timestamp', component: TimesTamp },
    { path: '/randnum', component: RandNum },
]

const router = new VueRouter({
    mode: 'hash',
    routes
})
export default router;
