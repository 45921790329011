<template>
  <div class="wrap">
    <div>
    <el-button type="primary"  size="small" v-show="showJsonBtn" round @click="formatData">格式化</el-button>
    <el-button type="primary" size="small" v-show="showJsonBtn" round @click="compressData">压缩</el-button>
  </div>
    <div>
    <el-row>
      <el-col :span="12">
        <el-input v-model="jsonStr" :autosize="{ minRows: 28, maxRows: 29}"  type="textarea" @input="clickFormat" placeholder="输入Json内容" />
      </el-col>

      <el-col :span="12">
        <json-view
          :value="parseStr"
          :expand-depth=10
          copyable
          boxed
          theme="my-awesome-json-theme">
      </json-view>
      </el-col>
    </el-row>
  </div>
  </div>
</template>
 
<script>
  import jsonView  from 'vue-json-viewer'
  export default {
    name: 'JsonParse',
    data: function() {
      return {
        jsonStr: '',
        parseStr: '',
        showJsonBtn: false
      }
    },
    components:{
        jsonView
    },
    methods: {
       clickFormat(){
        if(this.jsonStr == '') {
          this.parseStr = '';
          this.showJsonBtn = false
          return false;
        }
        this.showJsonBtn = true
        try {
          this.parseStr = JSON.parse(this.jsonStr)
        } catch (e) {
          this.showJsonBtn = false
          this.parseStr = e.message
        }
      },
      formatData() {
        this.jsonStr = JSON.stringify(JSON.parse(this.jsonStr), null, '\t')
      },
      compressData() {
        this.jsonStr = JSON.stringify(JSON.parse(this.jsonStr));
      }
    }
  }
  </script>
  
  <style lang="scss">
  .wrap{margin-top: 30px;}
  .my-awesome-json-theme {
    background: #fff;
    white-space: nowrap;
    color: #525252;
    font-size: 16px;
    line-height: 28px;
    max-height: 620px;
    
    overflow:scroll;
    font-family: Consolas, Menlo, Courier, monospace;
    .jv-ellipsis {
      color: #999;
      background-color: #eee;
      display: inline-block;
      line-height: 0.9;
      font-size: 0.9em;
      padding: 0px 4px 2px 4px;
      border-radius: 3px;
      vertical-align: 2px;
      cursor: pointer;
      user-select: none;
    }
    .jv-button { color: #49b3ff }
    .jv-key { color: #111111 }
    .jv-item {
      &.jv-array { color: #111111 }
      &.jv-boolean { color: #fc1e70 }
      &.jv-function { color: #067bca }
      &.jv-number { color: #fc1e70 }
      &.jv-object { color: #111111 }
      &.jv-undefined { color: #e08331 }
      &.jv-string {
        color: #42b983;
        word-break: break-word;
        white-space: normal;
      }
    }
    .jv-code {
      .jv-toggle {
        &:before {
          padding: 0px 2px;
          border-radius: 2px;
        }
        &:hover {
          &:before {
            background: #eee;
          }
        }
      }
    }
  }
  </style>