<template>
    <div class="wrap">
        <el-form :inline="true" label-position="right" label-width="100px"   class="demo-form-inline">
        <el-row>
            <el-col :span="24">
                <el-form-item label="长度">
                    <el-select v-model="value" placeholder="请选择">
                        <el-option
                          v-for="item in options"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                        </el-option>
                      </el-select>
                  </el-form-item>
                  <el-button type="primary"  size="small"  round  @click="handleRand">生成</el-button>
            </el-col> 
            
          </el-row>

          <el-row>
            
                <el-form-item label="随机值">
                    <el-col :span="24">
                    <el-input v-model="rand_vale" style="width: 550px;"   placeholder="请输入内容"></el-input>
                </el-col>
                </el-form-item>
            
          </el-row>
        </el-form>
    </div>

</template>
<script>
export default {
    name: 'RandNum',
    data: function() {
      return {
        options: [{
          value: '5',
          label: '5位'
        }, {
          value: '6',
          label: '6位'
        }, {
          value: '9',
          label: '9位'
        }, {
          value: '12',
          label: '位'
        }, {
          value: '16',
          label: '16位'
        }, {
          value: '32',
          label: '32位'
        }, {
          value: '64',
          label: '64位'
        }],
        value: '',
        rand_vale: ''
      }
    },
    created() {
        
    },
    mounted() {
        this.value = this.options[5].value
    },
    methods: {
      handleRand: function() {
        const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'; // 可选字符集合
        let password = '';
        for (let i = 0; i < this.value; i++) {
          password += charset[Math.floor(Math.random() * charset.length)]; // 从字符集合中随机选择一个字符
        }

        this.rand_vale = password;
      }
    }
}

</script>