<template>
    <div class="wrap">
        <el-form :inline="true" label-position="right" label-width="100px"   class="demo-form-inline">
        <el-row>
            <el-col :span="24">
                <el-form-item label="现在">
                    <el-input  v-model="currentTime"></el-input>
                  </el-form-item>
            </el-col> 
          </el-row>

          <el-row>
            <el-col :span="24">
                <el-form-item label="时间戳" flexed>
               
                    <el-form-item prop="date1">
                    <el-input  v-model="unixTime"></el-input>
                    
                    </el-form-item>
                    <el-button type="primary"  size="small"  round  @click="unixToTimeBtn">转</el-button>
                    <el-form-item prop="date2">
                    <el-input  v-model="unixTimeFormat"></el-input>
                    </el-form-item>
               
                </el-form-item>
            </el-col>
          </el-row>
          

          <el-row>
            <el-col :span="24">
                <el-form-item label="时间" flexed>
               
                    <el-form-item prop="date1">
                    <el-input  v-model="timeFormat"></el-input>
                    
                    </el-form-item>
                    <el-button type="primary"  size="small"  round @click="timeToUnixBtn">转</el-button>
                    <el-form-item prop="date2">
                    <el-input  v-model="timeToUnix"></el-input>
                    </el-form-item>
               
                </el-form-item>
            </el-col>
          </el-row>
        </el-form>

        
    </div>
    

</template>
<script>
let moment = require("moment") // 引入
  export default {
    name: 'TimesTamp',
    data: function() {
      return {
        currentTime: '',
        unixTime: '',
        unixTimeFormat: '',
        timeFormat: '',
        timeToUnix: '',
      }
    },
    created() {
        this.currentTime = parseInt(new Date().getTime() / 1000)
        this.unixTime = parseInt(new Date().getTime() / 1000) + ''
        this.timeFormat = moment(this.nowDate).format('YYYY-MM-DD HH:mm:ss')
    },
    mounted() {
        setInterval(()=>{      
            let sec = 1;
            this.currentTime += sec;
        },1000)
    },
    methods: {
        unixToTimeBtn(){
            this.unixTimeFormat = moment(parseInt(this.unixTime*1000)).format('YYYY-MM-DD HH:mm:ss')
       },
       timeToUnixBtn() {
            this.timeToUnix =  moment(this.timeFormat).unix(); 
       }

    }
}
</script>